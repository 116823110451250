@font-face {
  font-family: 'Replica LL';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Replica LL'), local('ReplicaLL-Regular'), url(./ReplicaLL-Regular.woff2) format('woff2'), url(./ReplicaLL-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Replica LL';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: local('Replica LL'), local('ReplicaLL-Bold'), url(./ReplicaLL-Bold.woff2) format('woff2'), url(./ReplicaLL-Bold.ttf) format('truetype');
}
*{
  font-family: 'Replica LL', 'Open Sans', sans-serif;
}
